import React from 'react';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="about"
          className="download-section pt-100 background-img"
          style={{
            backgroundImage: "url('img/trip-itineraries-bg.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="download-content text-white pb-100">
                  <h2 className="text-white">How did it start?</h2>
                  <p>
                    ExplorOFF co-founder Yulia Vasilyeva was trying to plan a
                    3-days trip on a short notice. She and her friends
                    researched several places around their location and after a
                    long group chat discussion decided to visit St Catalina
                    Island. It took them even longer to find activities nearby.
                    Once they were ready to book they found out that the fairy
                    to the island is not available on the day of their travel.
                    The time was wasted, and everyone discouraged. This lead to
                    the idea of ExplorOFF.
                  </p>
                  <p>
                    ExplorOFF co-founder Anton Putra, who has 10+ years of
                    Software Engineering, Big Data, and Full-Stack app
                    development experience, was already working in the travel
                    industry when the idea of ExplorOFF got created. As a
                    spontaneous adventurer himself, Anton got excited about AI
                    based trip curation and had a clear vision of how to make it
                    happen.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img align-bottom">
                  <img
                    src="img/app-hand-top.png"
                    alt="download"
                    className="img-fluid-mobile circle-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Download;
