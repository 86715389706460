import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="info-utah" className="about-us pt-trip gray-light-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    {this.props.showTitle && this.props.showTitle === true && (
                      <title className="badge badge-primary badge-pill">
                        Glacier National Park Memorial Day Weekend Guide
                      </title>
                     )}
                    <h1>
                    Glacier National Park Memorial Day Weekend Guide <br />
                    </h1>
                  <p>
                  Memorial day weekend is the perfect time to visit Glacier National Park! Although this early in the season some roads are closed due to snow, it is less crowded, and bicyclists are welcome on closed park roads. 
                  </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
    </React.Fragment>
  );
  }
}
export default About;