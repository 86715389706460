import React from 'react';
import _data from '../../data';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      aboutUs: _data.aboutUs,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section id="about" className="about-us ptb-100 background-shape-img">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-7">
                  <div className="about-content-left section-heading">
                    <h2>Full Road Trip Itineraries on Google Maps</h2>

                    <div className="single-feature mb-4 mt-5">
                      <div className="icon-box-wrap d-flex align-items-center mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-1.svg"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Stop wasting your time researching road trip ideas and quick weekend getaways. Plan your hikes, view points and outdoor activities and pin them to your google maps road trip only with ExplorOFF.
                        </p>
                      </div>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-2.svg"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Vote and comment on activities with your travel group
                          before making the decision.
                        </p>
                      </div>
                      <p></p>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-3.svg"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Book activities and lodging all through one place and
                          customize your itinerary.
                        </p>
                      </div>
                      <p></p>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-4.svg"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Get expert tips, pack your bags with a checklist and
                          order missing items.
                        </p>
                      </div>
                      <p></p>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-5.svg"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Get notified about hot spots to visit nearby.
                        </p>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="about-content-right ">
                    <img
                      src="img/image-11.png"
                      alt="about us"
                      className="img-fluid-mobile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
