import { call, put, takeEvery } from 'redux-saga/effects'
import { SUBSCRIBE, SUBSCRIBE_SUCCEEDED } from '../constants/types.js'
import { saveEmail } from '../api/actions'

function* fetchUser(action) {
  try {
    const email = yield call(saveEmail, action.email)
    yield put({ type: SUBSCRIBE_SUCCEEDED, email: email })
  } catch (e) {
    yield put({ type: 'SUBSCRIBE_FAILED', message: e.message })
  }
}

function* mySaga() {
  yield takeEvery(SUBSCRIBE, fetchUser)
}

export default mySaga
