import React from 'react';

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="features" className="feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img align-bottom">
                  <img
                    src="img/startup-weekend.png"
                    alt="download"
                    className="img-fluid-mobile circle-img"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>StartUp Weekend Las Vegas</h2>
                  <p>
                    ExplorOFF has earned 1st place at the StartUp Weekend Las
                    Vegas! In two days of this event, the ExplorOFF team was
                    brainstorming and validating the idea, surveying the target
                    market and making changes to the original prototype.
                    ExplorOFF looks forward to new wins this year!
                  </p>
                  <p>
                    If you enjoy everything on the go, have limited spare time
                    and want to explore instead of spending time on research and
                    planning, download ExplorOFF now!
                  </p>
                  {/* <ul className="list-inline mt-5"> */}
                  {/* <li className="list-inline-item"> */}
                  {/* <div className="mr-3 icon-box border"> */}
                  {/* <img */}
                  {/* src="img/image-icon-2.svg" */}
                  {/* alt="icon" */}
                  {/* className="img-fluid" */}
                  {/* /> */}
                  {/* </div> */}
                  {/* </li> */}
                  {/* </ul> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
