import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="info-utah" className="about-us pt-trip gray-light-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    {this.props.showTitle && this.props.showTitle === true && (
                      <title className="badge badge-primary badge-pill">
                        Utah National Parks Road Trip
                      </title>
                     )}
                    <h1>
                      Utah National Parks Road Trip <br />
                    </h1>
                  <p>
                  Have a long weekend ahead of you and thinking about Utah National Parks Road Trip? Click on <strong>"more options"</strong> on google maps below to open up a Full Utah National Parks Itinerary. Although you may need an entire week to explore, you can start and end your Utah trip in just a few days!
                  </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
    </React.Fragment>
  );
  }
}
export default About;