import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="map-utah" className="about-us gray-light-bg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m72!1m8!1m3!1d3231453.9068511217!2d-111.163432!3d37.726327!3m2!1i1024!2i768!4f13.1!4m61!3e0!4m5!1s0x80cac39954642ae1%3A0x9363d8a5c7991bbe!2sZion%20National%20Park!3m2!1d37.1992751!2d-112.98904909999999!4m5!1s0x8735691fee384c6d%3A0x759c376c7a49fd62!2sBryce%20Canyon%20City%2C%20Utah%2084764!3m2!1d37.6283161!2d-112.1676947!4m5!1s0x874b1a6f73fb4afd%3A0xe57b2f0f043270be!2s475%20E%20100%20N%2C%20Monroe%2C%20UT%2084754!3m2!1d38.6338903!2d-112.1084171!4m5!1s0x874a00ff07e7a253%3A0xde3bec53484fff07!2sCapitol%20Reef%20National%20Park%2C%20Utah!3m2!1d38.0877312!2d-111.1354983!4m5!1s0x8749039eeb3169f5%3A0xa129ca95a740ebcf!2sSpotted%20Wolf%20Canyon%2C%20Utah%2084525!3m2!1d38.9263634!2d-110.40681699999999!4m5!1s0x80caf61a860daa51%3A0x4f42ea69d128c495!2sArches%20National%20Park%2C%20Utah!3m2!1d38.733081!2d-109.5925139!4m5!1s0x8747e1d08c3b40e3%3A0xebf740161a56996f!2s1140%20N%20Main%20St%2C%20Moab%2C%20UT%2084532!3m2!1d38.5882712!2d-109.56023049999999!4m5!1s0x8747e1f3f9de44f1%3A0x26a0070e115eb31b!2s606%20South%20Main%20Street%2C%20Moab%2C%20Utah%2084532!3m2!1d38.5640601!2d-109.55018419999999!4m5!1s0x8747e1ee4518a6a9%3A0x15a452a9c502e6aa!2sCanyonlands%20National%20Park%2C%20Utah!3m2!1d38.2135733!2d-109.9025345!4m5!1s0x87346ced52b21091%3A0xb623646960566829!2sHorseshoe%20Bend%2C%20Arizona%2086040!3m2!1d36.879061199999995!2d-111.5103627!5e0!3m2!1sen!2sus!4v1639276642654!5m2!1sen!2sus"
            width="100%"
            height="250">
          </iframe>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
