import firebase from './firestore'

export function saveEmail(payload) {
  const db = firebase.firestore()

  return new Promise((resolve, reject) => {
    const emailValid = payload.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )

    if (!emailValid) {
      console.log(`Email: "${payload.email}" is not valid!`)
      payload.disableBtn = false
      payload.btnText = 'Invalid Email!'
      payload.btnClass = 'solid-btn-fail'
      payload.email = ''
      resolve(payload)
      return
    }

    db.collection('subscribers')
      .where('email', '==', payload.email)
      .get()
      .then(function(results) {
        if (results.empty) {
          db.collection('subscribers')
            .add({
              email: payload.email,
            })
            .then(function(docRef) {
              console.log(`"${payload.email}" has been subscribed!`)
              payload.disableBtn = true
              payload.btnText = 'Subscribed!'
              payload.btnClass = 'solid-btn-success'
              payload.email = ''
              resolve(payload)
            })
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        } else {
          results.forEach(function(doc) {
            console.log(`Provided email "${payload.email}" exists!`)
            payload.btnText = 'Email Exists!'
            payload.btnClass = 'solid-btn-fail'
            payload.email = ''
            resolve('')
          })
        }
      })
      .catch(function(error) {
        console.log('Error getting documents: ', error)
      })
  })
}
