import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="info-colorado-river" className="about-us pt-trip gray-light-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    {this.props.showTitle && this.props.showTitle === true && (
                      <title className="badge badge-primary badge-pill">
                        2-Day Colorado River Kayaking Trip
                      </title>
                     )}
                    <h1>
                      2-Day Colorado River Kayaking Trip <br />
                    </h1>
                  <p>
                  If you have no plans for your next weekend and right now it is February through October, you have to go and kayak down the Colorado River in Page, AZ! Don't forget to stop at Antelope Canyon and Horseshoe Bend! Click on <strong>"more options"</strong> on google maps below to open up a Full Colorado River Kayaking Trip Itinerary. 
                  </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
    </React.Fragment>
  );
  }
}
export default About;