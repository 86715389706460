import React from 'react';

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="features" className="feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="https://storage.googleapis.com/exploroff-web/outdoor-activities/things-to-do-in-utah/moab-view-utv-tour.jpg"
                    alt="Colorado River view at Moab"
                    className="img-fluid rounded-corners"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    You Drive Experience <br />
                    <span>through the Hell's Revenge Trail</span>
                  </h2>
                  <p>
                    Cruise off on Kawasaki Teryx 4 through the Hell's Revenge
                    Trail. The trail is full of steep climbs and stunning views
                    of the La Sal Mountains, Abyss Canyon, and Colorado River.
                    Follow along a thrilling route that takes you crawling up a
                    rock incline called the Devil’s Backbone. Explore the
                    slickrock, formed from Navajo sandstone, before you descend
                    into a low point in the narrow canyon, named Lake Michigan
                    for the water that pools from rainfall. Next, make a brief
                    stop at the fossilized dinosaur tracks near Lion’s Back
                    before you proceed to Echo Canyon. Trust us, the panoramic
                    views of the Wild West will steal your heart and take your
                    breath away! <br />
                    <br />
                    Traversing sandstone domes and slickrock ledges will keep
                    you on the edge of your seat. Make sure you are ready for
                    it! Grab your buddies and book now for your first weekend
                    out in Utah.
                  </p>

                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active h6"
                          href="#tab6-1"
                          data-toggle="tab"
                        >
                          <span className="ti-money"></span>
                          Rates
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-2"
                          data-toggle="tab"
                        >
                          <span className="ti-time"></span>
                          Duration
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="https://fareharbor.com/embeds/book/moabtourismcenter/items/119802/?sheet=259014&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no"
                          data-toggle="tab"
                        >
                          <span className="ti-shopping-cart-full"></span> Book
                          Now
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content feature-tab-content">
                      <div className="tab-pane active" id="tab6-1">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-pink mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">Adults - $99 - $109</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-pink mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Children - $69.00 - $79.00
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-2">
                        <div className="row">
                          <div className="col single-feature mb-4">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-stats-up rounded mr-3 icon icon-color-2"></span>
                              <h5 className="mb-0">2.5 hrs</h5>
                            </div>
                            <p>Enjoy every moment of your drive.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
