import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="map-colorado-river" className="about-us gray-light-bg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m40!1m12!1m3!1d204538.1696065169!2d-111.69548100522648!3d36.77275285706364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m25!3e0!4m5!1s0x87315587cc513269%3A0xf3e25ef51d3e685a!2sAZ-98%2C%20Page%2C%20AZ!3m2!1d36.8951132!2d-111.4895931!4m5!1s0x87346ced52b21091%3A0xb623646960566829!2sHorseshoe%20Bend%2C%20Arizona!3m2!1d36.879061199999995!2d-111.5103627!4m5!1s0x8734135ee8ae30b1%3A0xfb3c0c55aff9d5d8!2s816%20Coppermine%20Rd%2C%20Page%2C%20AZ%2086040!3m2!1d36.9067107!2d-111.4555824!4m5!1s0x87346ebdbe506821%3A0x9211872ee94b8317!2sLees%20Ferry%20Launch%2C%20Spencer%20Trail%2C%20Marble%20Canyon%2C%20AZ!3m2!1d36.865777!2d-111.58646689999999!5e0!3m2!1sen!2sus!4v1644204802631!5m2!1sen!2sus"
            width="100%"
            height="250">
          </iframe>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
