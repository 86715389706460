import React from 'react';
import { connect } from 'react-redux';

class privacyHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav
            className={
              'navbar navbar-expand-lg fixed-top yulia-header-background'
            }
            // className={
            //   'navbar navbar-expand-lg fixed-top ' +
            //   (this.props.bgColor && this.props.bgColor === 'white'
            //     ? 'custom-nav white-bg'
            //     : 'bg-transparent')
            // }
          >
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src={
                    this.props.bgColor && this.props.bgColor === 'white'
                      ? 'img/logo-color-1x.png'
                      : 'img/logo-white-1x.svg'
                  }
                  width="120"
                  alt="logo"
                  className="img-fluid-exploroff"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="https://exploroff.com"
                      id="navbarDropdownHome"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className='nav link dropdown-toggle' href="#"
                      id="navbarDropdownHome" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">Road Trip Itineraries

                    </a>
                    <div className='dropdown-menu submenu' aria-labelledby='navbarDropdownHome'>
                      <a className='dropdown-item' href="/kayak-colorado-river">
                      Colorado River Kayaking
                      </a>
                      <a className='dropdown-item' href="/utah-national-parks-road-trip">
                      Utah National Parks
                      </a>
                      <a className='dropdown-item' href="/things-to-do-in-vegas-besides-party">
                      Outdoor Activities in Las Vegas
                    </a>
                    <a className='dropdown-item' href="/glacier-national-park-memorial-day">
                    Glacier National Park
                    </a>
                    </div>
                 </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="/#features">
                      Featured Itineraries
                    </a>
                    </li>
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="/#Story">
                      Our Story
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="/#team">
                      Team
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="/outdoor-activities"
                    >
                      Top Activities
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a className="nav-link" href="/privacy">
                      Privacy
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({}))(privacyHeader);
