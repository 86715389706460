import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDhfCMNG6sEY1HMMHjywvjCOG2br9T4Y3o',
  authDomain: 'exploroff-host-prod.firebaseapp.com',
  databaseURL: 'https://exploroff-host-prod.firebaseio.com',
  projectId: 'exploroff-host-prod',
  storageBucket: 'exploroff-host-prod.appspot.com',
  messagingSenderId: '791392933819',
  appId: '1:791392933819:web:62a4209af0f4b454d4169e',
  measurementId: 'G-HFBMT210EJ',
}

firebase.initializeApp(firebaseConfig)

export default firebase
