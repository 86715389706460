import React from 'react';
import { connect } from 'react-redux';

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="download"
          className="video-promo ptb-100 tild background-img"
          style={{
            backgroundImage:
              "url('https://storage.googleapis.com/exploroff-web/outdoor-activities/things-to-do-in-utah/off-road-tour-moab.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="video-promo-content mt-4 text-center">
                  <a
                    href="https://www.youtube.com/watch?v=4iZoFQDp35E"
                    className="popup-youtube video-play-icon d-inline-block pink-color"
                  >
                    <span className="ti-control-play"></span>{' '}
                  </a>
                  <h5 className="mt-4 text-white">Your Tour Spoiler</h5>
                  <br />
                  <br />
                  {this.props.showDownloadBtn &&
                    this.props.showDownloadBtn === true && (
                      <div className="btn mb-3 outline-btn yulia-white-color-button">
                        <a href="https://fareharbor.com/embeds/book/moabtourismcenter/items/119802/?sheet=259014&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no">
                          <span className="ti-shopping-cart-full"></span> Book
                          Now
                        </a>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  state,
}))(Video);
