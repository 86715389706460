import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="map-nevada" className="about-us gray-light-bg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m76!1m12!1m3!1d5441122.408854744!2d-115.07352207486655!3d38.021426105634866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m61!3e0!4m5!1s0x80cecf0b58b22cf7%3A0xea459a6e3ff2981f!2s25804%20N%20Willow%20Beach%2C%20Willow%20Beach%2C%20AZ%2086445!3m2!1d35.8694744!2d-114.6609142!4m5!1s0x80c92ceb5c43d9e1%3A0x66870b1794d42911!2s18000%20US-93%2C%20Boulder%20City%2C%20NV%2089005!3m2!1d36.0099006!2d-114.78430689999999!4m5!1s0x80c8c42627db6a8d%3A0x42fa8dee83a7a2c3!2s4390%20Polaris%20Ave%2C%20Las%20Vegas%2C%20NV%2089103!3m2!1d36.1102397!2d-115.18518739999999!4m5!1s0x80c8e7408a6f9c39%3A0x134fb0bef4005df4!2s6925%20Speedway%20Blvd%20c106%2C%20Las%20Vegas%2C%20NV%2089115!3m2!1d36.278287899999995!2d-115.0198508!4m5!1s0x80c96b2bca0cabf5%3A0x4c5d735aaf7cb075!2sValley%20of%20Fire%20State%20Park%2C%20Valley%20of%20Fire%20Hwy%2C%20Overton%2C%20NV!3m2!1d36.5073322!2d-114.5352405!4m5!1s0x80c89d6bddad883b%3A0xe7a9fe7f67217d73!2s1208%20Cathedral%20Picnic%20Area%2C%20Mt%20Charleston%2C%20NV%2089124!3m2!1d36.2564929!2d-115.6445519!4m5!1s0x80c897e06fef0937%3A0xff3872b5229c6129!2sSandstone%20Quarry%2C%20Nevada%2089161!3m2!1d36.1638945!2d-115.4507378!4m5!1s0x80c8a2a8b7693b37%3A0x47081de2e753c4fb!2sIce%20Box%20Canyon%2C%20Scenic%20Loop%20Dr%2C%20Las%20Vegas%2C%20NV%2089161!3m2!1d36.1501406!2d-115.48399819999999!4m5!1s0x80c8a2ce40e21801%3A0x18fd816be248d130!2sPine%20Creek%2C%20Nevada%2089161!3m2!1d36.1243914!2d-115.4809964!4m5!1s0x80c8bb60215b0c93%3A0xc6f6d6fe54fbb116!2sFirst%20Creek%20Trailhead%2C%20NV-159%2C%20Las%20Vegas%2C%20NV%2089161!3m2!1d36.081198799999996!2d-115.44816879999999!5e0!3m2!1sen!2sus!4v1642978781455!5m2!1sen!2sus"
            width="100%"
            height="250">
          </iframe>
        </section>
      </React.Fragment>
    );
  }
}

export default About;