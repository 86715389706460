import React, { Component } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import PrivacyHeader from '../components/Header/privacyHeader';
import HeroSection from '../components/HeroSection/HeroSection1';
import PromoSection from '../components/PromoSection';
import AboutSection from '../components/AboutUs';
import FeatureSection from '../components/Features';
import Story from '../components/Story';
import DownloadSection from '../components/Download';
import Feature2 from '../components/Features/Feature2';
import TeamMember from '../components/TeamMember';
import SubscribeSection from '../components/Subscribe';
import FooterSection from '../components/Footer';

import FeatureSection3 from '../components/Features/Feature3';
import VideoSection from '../components/Video';

import { Helmet } from 'react-helmet';

class ExampleActivity extends Component {
  render() {
    return (
      <>
        <MessengerCustomerChat
          pageId="101590111335192"
          appId="695593052231950" 
        />
        <Helmet>
          <title>Moab UTV Tours</title>
          <meta
            name="description"
            content="Moab UTV tour through Hell's Revenge is a must-do off-road adventure for your weekend in Utah."
          />
          <meta name="keywords" content="moab utv tours" />
        </Helmet>
        <PrivacyHeader />
        <div className="main">
          <Feature2 hideFaq={true} />
          <VideoSection showDownloadBtn={false} />
          <FeatureSection3 />
        </div>
        <FooterSection />
      </>
    );
  }
}

export default ExampleActivity;
