import React from 'react';

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div
          id="features"
          className={
            'feature-section ptb-100 ' +
            (this.props.bgColor && this.props.bgColor === 'gray'
              ? 'gray-light-bg'
              : '')
          }
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    Moab UTV Tours <br />
                    <span>4x4 driving tours start at $99</span>
                  </h2>
                  <p>
                    Highlight your trip to Moab, UT with this 2.5-hour off-road
                    drive! Although this scenic adventure hub will keep you
                    busy, you can’t experience Moab to the max unless you hop on
                    UTV. <br />
                  </p>
                  <a
                    href="https://fareharbor.com/embeds/book/moabtourismcenter/items/119802/?sheet=259014&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no"
                    class="btn mb-3 outline-btn pink-color"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>

            <div className="row row-grid align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-car"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Real 4 by 4 Tour</h5>
                    <p className="mb-0">
                      Travel in a small group of up to four vehicles with a
                      maximum of four people per vehicle.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-alert"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Safety First</h5>
                    <p className="mb-0">
                      Slip behind the wheel only after a safety briefing.
                      Otherwise take the passenger seat.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-heart"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Family type of adventure</h5>
                    <p className="mb-0">
                      Kids love this ride! Take them with you so they can
                      remember this trip too.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="position-relative" style={{ zIndex: 10 }}>
                  <img
                    alt="A family on Moab UTV tour"
                    src="https://storage.googleapis.com/exploroff-web/outdoor-activities/things-to-do-in-utah/moab-utv-tours.jpg"
                    className="img-center img-fluid rounded-corners"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-star"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Natural Theme Park</h5>
                    <p className="mb-0">
                      Moab is a natural-beauty-lovers dream. Don't put it on
                      your bucket list instead visit now!
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-info"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Learn some</h5>
                    <p className="mb-0">
                      Drive along with an experienced guide to find out more
                      about Moab.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-shine"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Must-have experience</h5>
                    <p className="mb-0">
                      There is nothing like the drive through Moab. Hop on the
                      UTV and explore Utah off-road.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
