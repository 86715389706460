import React from 'react';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
  const date = new Date();
  const year = date.getFullYear();
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={
              'footer-top background-img-2 ' +
              (this.props.noSubscription ? 'py-5' : 'pt-150 pb-5')
            }
            style={{
              backgroundImage: "url('img/footer-bg.png')",
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center top',
              backgroundSize: 'cover',
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src="img/logo-white-1x.svg"
                      alt="footer logo"
                      width="120"
                      className="img-fluid mb-3"
                    />
                    <p>
                      We are exploring on our time off! Follow us on social for outdoor adventure inspo!
                    </p>

                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a
                            href="https://www.facebook.com/exploroff"
                            target="_blank"
                            title="Facebook"
                          >
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.linkedin.com/company/exploroff"
                            target="_blank"
                            title="Linkedin"
                          >
                            <span className="ti-linkedin"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.tiktok.com/@exploroff"
                            target="_blank"
                            title="TikTok"
                          >
                            <span className="ti-music"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.instagram.com/exploroff"
                            target="_blank"
                            title="Instagram"
                          >
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.youtube.com/@exploroff"
                            target="_blank"
                            title="YouTube"
                          >
                            <span className="ti-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Road Trip Itineraries</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a href="/kayak-colorado-river">Colorado River Kayaking</a>
                      </li>
                      <li className="mb-2">
                        <a href="/utah-national-parks-road-trip">Utah National Parks</a>
                      </li>
                      <li className="mb-2">
                        <a href="/things-to-do-in-vegas-besides-party">Outdoor Activities in Las Vegas</a>
                      </li>
                      <li className="mb-2">
                        <a href="/glacier-national-park-memorial-day">Glacier National Park</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Support</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        Seattle, WA
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{' '}
                        <a href="tel:+17247995786">724-799-5786</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:mail@example.com">
                          {' '}
                          yuliavasilyeva1993@gmail.com
                        </a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="/#"> exploroff.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Location</h5>
                    <img
                      src="img/map.png"
                      alt="location map"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                    Copyrights © {year}. All rights reserved by
                    <a href="https://exploroff.com/"> ExplorOFF</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
