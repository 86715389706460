import React from 'react';
import _data from '../../data';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="colorado-river-pricing"
            className={
              'package-section ptb-55 ' +
              (this.props.bgColor && this.props.bgColor === 'gray'
                ? 'gray-light-bg'
                : 'background-shape-right')
            }
          >
            <div className="container">
              <div className="row justify-content-center">
                {(this.state.price.coloradoRiverPackages || []).map((_package, index) => {
                  return (
                    <div className="col-lg-4 col-md" key={index}>
                      <div
                        className={
                          'card text-center single-pricing-pack ' +
                          (_package.isFeatured ? 'popular-price' : '')
                        }
                      >
                        <div className="pt-4">
                          <h5>{_package.license}</h5>
                        </div>
                        <div className="pricing-img mt-4">
                          <img
                            src={_package.photoImg}
                            alt={_package.photoImg}
                            className="img-fluid"
                          />
                        </div>
                        <div className="card-header py-4 border-0 pricing-header">
                          <div className="h1 text-center mb-0">
                            <span className="price font-weight-bolder">
                              {_package.price}
                            </span>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                            {(_package.features || []).map((feature) => {
                              return <li key={feature}>{feature}</li>;
                            })}
                          </ul>

                          <a
                            href={_package.fareharborURL}
                            className={
                              'btn mb-3 ' +
                              (_package.isFeatured
                                ? 'trip-solid-btn'
                                : 'trip-outline-btn')
                            }
                          >
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme !== 'four' && (
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div id="accordion-1" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-1"
                            aria-expanded="false"
                            aria-controls="collapse-1-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-home mr-3"></span>Where can I camp overnight during my Colorado River Kayaking Trip?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-1"
                            className="collapse"
                            aria-labelledby="heading-1-1"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                There are designated campsites with toilets along the way.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-2"
                            aria-expanded="false"
                            aria-controls="collapse-1-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-briefcase mr-3"></span>How to pack my kayak for a 2-day Colorado River trip?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-2"
                            className="collapse"
                            aria-labelledby="heading-1-2"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                We recommend taking camping staff with you in roll-top dry bags (you can find them online or in Walmart for as cheap as $4). Pack efficiently and do not place heavy items on the outside of your kayak. Bring at least four liters of water per person per day.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-3"
                            aria-expanded="false"
                            aria-controls="collapse-1-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-star mr-3"></span>How hard is it to kayak the Colorado River?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-3"
                            className="collapse"
                            aria-labelledby="heading-1-3"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                There are no rapids, but wind sometimes makes it harder to move forward. Getting an early start is vital since the mid-day heat and sun can make paddling very tiring. Most people can figure out the basic paddling strokes within a few minutes, but you will become much more efficient over time. 
                              </p>
                            </div>
                          </div>
                      </div>
                      <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-4"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-4"
                            aria-expanded="false"
                            aria-controls="collapse-1-4"
                          >
                            <h6 className="mb-0">
                              <span className="ti-money mr-3"></span>Can I paddle the Colorado River without paying for a backhaul?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-4"
                            className="collapse"
                            aria-labelledby="heading-1-4"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                No. The current is swift - even at times at low flow, it would be slow and tiring to try to paddle upstream. Lees Ferry is the only access point for watercraft. 
                              </p>
                            </div>
                          </div>
                      </div>
                     <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-5"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-5"
                            aria-expanded="false"
                            aria-controls="collapse-1-5"
                          >
                            <h6 className="mb-0">
                              <span className="ti-shield mr-3"></span>Is fishing on the Colorado River any good?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-5"
                            className="collapse"
                            aria-labelledby="heading-1-5"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                Fishing can be excellent on the Colorado River Float Trip. Make sure you have the required licenses, which can be purchased in Page, AZ.
                              </p>
                        </div>
                      </div>  
                      </div>
                      <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-6"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-6"
                            aria-expanded="false"
                            aria-controls="collapse-1-6"
                          >
                            <h6 className="mb-0">
                              <span className="ti-crown mr-3"></span>Do only experienced kayakers allowed to kayak the Colorado River?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-6"
                            className="collapse"
                            aria-labelledby="heading-1-6"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                               <p>
                                Kayaking the Colorado River is not quite as simple as hopping in your boat and paddling downstream. Safety equipment is required in many parts of the river. Permits are required for the Grand Canyon section. Understanding the difficulty ratings for the water where you will be kayaking is vital. In the United States, river waters are rated on their level of difficulty using the American Whitewater International Scale of River Difficulty.
                              <ul>
                                <li>Class A - water flowing at fewer than 2MPH.</li>
                                <li>Class B -  water moves between 2-4MPH</li>
                                <li>Class C - water flows at a speed of 6+MPH</li>
                              </ul>
                               </p>
                             </div>
                          </div>
                       </div> 
                    </div>
                    </div>
                    <div className="col-lg-6">
                      <div id="accordion-2" className="accordion accordion-faq">
                         <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-1"
                            aria-expanded="false"
                            aria-controls="collapse-2-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-ruler mr-3"></span>How cold is the Colorado River?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-1"
                            className="collapse"
                            aria-labelledby="heading-2-1"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                               Colorado River temperature averages 50° year-round.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-2"
                            aria-expanded="false"
                            aria-controls="collapse-2-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-gallery mr-3"></span>What section of the Colorado River is good for kayaking and hiking?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-2"
                            className="collapse"
                            aria-labelledby="heading-2-2"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                            Page, AZ is one of the best kayaking sections of the Colorado River. Kayak between massive canyon walls and take a day hike on Horseshoe Bend.
                            The Black Canyon is another great section to kayak and hike. Finally, you can kayak down the Colorado River starting from Hoover Dam.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-3"
                            aria-expanded="false"
                            aria-controls="collapse-2-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-id-badge mr-3"></span>Do you need a permit to kayak the Colorado River?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-3"
                            className="collapse"
                            aria-labelledby="heading-2-3"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                A river permit is required for noncommercial river travel on the Colorado River only through Grand Canyon National Park. Apply for your permit on <a href="https://grcariverpermits.nps.gov/index.cfm">grcariverpermits</a>.
                              </p>
                            </div>
                          </div>
                      </div>
                      <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-4"
                            aria-expanded="false"
                            aria-controls="collapse-2-4"
                          >
                            <h6 className="mb-0">
                              <span className="ti-cloud mr-3"></span>Can I cancel my kayak rentals if there is bad weather?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-4"
                            className="collapse"
                            aria-labelledby="heading-2-4"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                Cancellations due to prolonged periods of dangerous conditions will receive a full refund. High sustained winds and intense lightning activity are the two main factors kayak rentals consider when determining if conditions are hazardous.
                              </p>
                            </div>
                          </div>
                        </div> 
                     <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-5"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-5"
                            aria-expanded="false"
                            aria-controls="collapse-2-5"
                          >
                            <h6 className="mb-0">
                              <span className="ti-arrow-top-right mr-3"></span>Can I kayak the upper Colorado River?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-5"
                            className="collapse"
                            aria-labelledby="heading-2-5"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                Yes, June and July are the most popular months for the upper Colorado River kayaking trip.
                              </p>
                            </div>
                        </div>
                      </div>
                     <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-6"
                            aria-expanded="false"
                            aria-controls="collapse-2-6"
                          >
                            <h6 className="mb-0">
                              <span className="ti-alert mr-3"></span>Are there alligators on the Colorado River?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-6"
                            className="collapse"
                            aria-labelledby="heading-2-6"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                Alligators have a better tolerance for cold and can potentially survive in the Colorado River. However, the lack of suitable alligator habitats would make them unlikely to breed and proliferate in number.
                              </p>
                            </div>
                          </div>
                        </div>
                        </div>
                        </div>
                  </div>
             )}

              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme === 'four' && (
                  <div className="row pt-5">
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>How can I pay for this?</h5>
                        <p>
                          Intrinsicly implement high standards in strategic
                          theme areas via inexpensive solutions. Assertively
                          conceptualize prospective bandwidth whereas
                          client-based imperatives.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is it possible to pay yearly?</h5>
                        <p>
                          Assertively iterate user friendly innovation without
                          open-source markets. Monotonectally extend resource
                          sucking manufactured products without high-payoff
                          paradigms. Objectively customize ubiquitous
                          information before economically sound relationships.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Do you offer discounts on multiple items?</h5>
                        <p>
                          Dramatically target focused testing procedures after
                          holistic ideas. Collaboratively maximize high-payoff
                          ROI and value-added products. Distinctively deliver
                          cooperative collaboration and idea-sharing whereas
                          customized
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is VAT included in plan prices?</h5>
                        <p>
                          Distinctively simplify high-quality initiatives for
                          highly efficient applications. Monotonectally
                          repurpose integrated customer service after magnetic
                          e-services.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Will I pay more for some features?</h5>
                        <p>
                          Enthusiastically pontificate resource-leveling supply
                          chains whereas scalable markets. Authoritatively
                          streamline resource maximizing methods of empowerment
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>
                          Why are there no limits on the number of messages?
                        </h5>
                        <p>
                          Assertively target turnkey ideas for market-driven
                          portals. Appropriately e-enable world-class
                          intellectual capital whereas 2.0 mindshare.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
