import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import GoogleMapReact from 'google-map-react';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { Alert, AlertTitle } from '@material-ui/lab';

const AnyReactComponent = ({ text }) => (
  <div style={{ fontSize: 36 }}>{text}</div>
);
function createData(
  name,
  availability,
  contact,
  price,
  rv_hoohups,
  features,
  image_url,
  longitude,
  latitude,
  destination_name
) {
  return {
    name,
    availability,
    contact,
    price,
    rv_hoohups,
    features,
    image_url,
    longitude,
    latitude,
    destination_name,
  };
}

const currencies = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const apiKey = 'iuhrf2389hf92hef0';

export default function ValidationTextFields() {
  const classes = useStyles();
  const [hideAlert, setHideAlert] = useState(true);
  const [currency, setCurrency] = useState(false);
  const [lon, setLon] = useState(-115.146855);
  const [lat, setLat] = useState(36.148764);
  const [destination, setDestination] = useState('');
  const [campsiteName, setCampsiteName] = useState('');
  const [contact, setContact] = useState('');
  const [price, setPrice] = useState('');
  const [availability, setAvailability] = useState('');
  const [features, setFeatures] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [errorMessgae, setErrorMessage] = useState('');
  const [hideSuccessAlert, setHideSuccessAlert] = useState(true);
  const [campsites, setCampsites] = useState([]);

  const handleChange = event => {
    setCurrency(event.target.value);
  };

  const getCampsites = async () => {
    try {
      const response = await fetch(
        'https://api.exploroff.com/api/v1/campsites?_user_id=672fg2678&_token=hnf987347gfh347&_lon=-119.51423&_lat=34.65374&_radius=99999&_page=1&_limit=10&_order_by=created_at',
        {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'x-api-key': apiKey,
          },
        }
      );
      const json = await response.json();
      if (!response.ok) {
        setHideAlert(false);
        console.log('errorrrr', json);
        setErrorMessage(json.error);
      } else {
        console.log(json);
        json.campsites.forEach(row => {
          console.log('row', row);
          setCampsites(prev =>
            prev.concat(
              createData(
                row.name,
                row.availability,
                row.contact,
                row.price,
                row.rv_hoohups,
                row.features,
                row.image_url,
                row.longitude,
                row.latitude,
                row.destination_name
              )
            )
          );
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCampsites();
  }, [hideSuccessAlert, errorMessgae]);

  const handleValueChange = (event, id) => {
    if (id === 'destination') {
      setDestination(event.target.value);
    } else if (id === 'campsiteName') {
      setCampsiteName(event.target.value);
    } else if (id === 'contact') {
      setContact(event.target.value);
    } else if (id === 'price') {
      setPrice(event.target.value);
    } else if (id === 'availability') {
      setAvailability(event.target.value);
    } else if (id === 'features') {
      setFeatures(event.target.value);
    } else if (id === 'longitude') {
      setLongitude(event.target.value);
    } else if (id === 'latitude') {
      setLatitude(event.target.value);
    }
  };

  const handleSave = async () => {
    if (
      !destination ||
      !campsiteName ||
      !availability ||
      !contact ||
      !price ||
      !features ||
      !longitude ||
      !latitude
    ) {
      setHideAlert(false);
      setErrorMessage('Fill out everything1!!!');
      return;
    }
    const body = {
      destinationName: destination,
      campsiteName: campsiteName,
      availability: availability,
      contact: contact,
      price: price,
      rv_hoohups: currency,
      features: features.split(','),
      longitude: longitude,
      latitude: latitude,
    };
    try {
      const response = await fetch(
        'https://api.exploroff.com/api/v1/campsites?_user_id=672fg2678&_token=hnf987347gfh347',
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'x-api-key': apiKey,
          },
          body: JSON.stringify(body),
        }
      );
      const json = await response.json();
      if (!response.ok) {
        setHideAlert(false);
        console.log('errorrrr', json);
        setErrorMessage(json.error);
      } else {
        console.log(json);
        setHideSuccessAlert(false);
        setHideAlert(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const defaultProps = {
    center: {
      lat: -115.146855,
      lng: 36.148764,
    },
    zoom: 10,
  };

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <div>
          <Alert hidden={hideAlert} severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessgae}
          </Alert>
          <Alert hidden={hideSuccessAlert} severity="success">
            <AlertTitle>Success</AlertTitle>
            Campsite was saved!
          </Alert>

          <TextField
            required
            id="outlined-required"
            label="Destination"
            defaultValue=""
            variant="outlined"
            value={destination}
            onChange={event => handleValueChange(event, 'destination')}
          />
          <TextField
            required
            id="outlined-required"
            label="Campsite Name"
            defaultValue=""
            variant="outlined"
            value={campsiteName}
            onChange={event => handleValueChange(event, 'campsiteName')}
          />
          <TextField
            required
            id="outlined-required"
            label="Contact"
            defaultValue=""
            variant="outlined"
            value={contact}
            onChange={event => handleValueChange(event, 'contact')}
          />
          <TextField
            required
            id="outlined-required"
            label="Price"
            defaultValue=""
            variant="outlined"
            value={price}
            onChange={event => handleValueChange(event, 'price')}
          />
          <TextField
            required
            id="outlined-required"
            label="Availability"
            defaultValue=""
            variant="outlined"
            value={availability}
            onChange={event => handleValueChange(event, 'availability')}
          />
          <TextField
            required
            id="standard-select-currency"
            select
            label="Select"
            value={currency}
            onChange={handleChange}
            helperText="RV Hoohups"
            variant="outlined"
          >
            {currencies.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            id="outlined-required"
            label="Features"
            defaultValue=""
            variant="outlined"
            helperText="MUST use COMMA to separate!"
            value={features}
            onChange={event => handleValueChange(event, 'features')}
          />
          <TextField
            required
            id="outlined-required"
            label="Longitude"
            defaultValue=""
            type="number"
            variant="outlined"
            value={longitude}
            onChange={event => handleValueChange(event, 'longitude')}
          />
          <TextField
            required
            id="outlined-required"
            label="Latitude"
            defaultValue=""
            type="number"
            variant="outlined"
            value={latitude}
            onChange={event => handleValueChange(event, 'latitude')}
          />
          <Button
            style={{ marginTop: 18, marginLeft: 40 }}
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
          >
            Save Campsite
          </Button>
        </div>
        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyDhfCMNG6sEY1HMMHjywvjCOG2br9T4Y3o',
            }}
            defaultCenter={{ lat: lat, lng: lon }}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent lat={latitude} lng={longitude} text="🏕️" />
          </GoogleMapReact>
        </div>
      </form>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Campsite Name</TableCell>
              <TableCell align="right">Destination</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Longitude</TableCell>
              <TableCell align="right">Latitude</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campsites.map(row => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.destination_name}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.longitude}</TableCell>
                <TableCell align="right">{row.latitude}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
