import React from 'react';
import _data from '../../data';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="utah-pricing"
            className={
              'package-section ptb-55 ' +
              (this.props.bgColor && this.props.bgColor === 'gray'
                ? 'gray-light-bg'
                : 'background-shape-right')
            }
          >
            <div className="container">
              <div className="row justify-content-center">
                {(this.state.price.utahPackages || []).map((_package, index) => {
                  return (
                    <div className="col-lg-4 col-md" key={index}>
                      <div
                        className={
                          'card text-center single-pricing-pack ' +
                          (_package.isFeatured ? 'popular-price' : '')
                        }
                      >
                        <div className="pt-4">
                          <h5>{_package.license}</h5>
                        </div>
                        <div className="pricing-img mt-4">
                          <img
                            src={_package.photoImg}
                            alt={_package.photoImg}
                            className="img-fluid"
                          />
                        </div>
                        <div className="card-header py-4 border-0 pricing-header">
                          <div className="h1 text-center mb-0">
                            <span className="price font-weight-bolder">
                              {_package.price}
                            </span>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                            {(_package.features || []).map((feature) => {
                              return <li key={feature}>{feature}</li>;
                            })}
                          </ul>

                          <a
                            href={_package.fareharborURL}
                            className={
                              'btn mb-3 ' +
                              (_package.isFeatured
                                ? 'trip-solid-btn'
                                : 'trip-outline-btn')
                            }
                          >
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme !== 'four' && (
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div id="accordion-1" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-1"
                            aria-expanded="false"
                            aria-controls="collapse-1-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span> When is the best time to visit Utah National Parks?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-1"
                            className="collapse"
                            aria-labelledby="heading-1-1"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                <strong>April–May</strong>, and <strong>September–October</strong> is the perfect time for a road trip through Utah National Parks. Compared to other times of the year, which are either too hot or too cold for hiking and outdoor activities, these four months are perfect weatherwise and less crowded. 
                              <br />
                                P.S. If you want to explore Utah with our team, shoot us a message on <a target='_blanc' className='intext-link' href="https://www.instagram.com/exploroff/">IG</a>!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-2"
                            aria-expanded="false"
                            aria-controls="collapse-1-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-gallery mr-3"></span> Where to stay while exploring the rim of Utah's national parks?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-2"
                            className="collapse"
                            aria-labelledby="heading-1-2"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                As a true explorer, you may want to stay in a tent, cabin, or even Teepee. Check out 
                               <a target='_blanc' className='intext-link' href="https://www.recreation.gov/"> recreation.gov</a> and 
                               <a target='_blanc' className='intext-link' href="https://www.hipcamp.com/"> hipcamp.com</a>.
                                <br />
                                P.S. Sometimes, you can contact hosts on Hipcamp directly and ask to get notified if the dates you are looking for are currently booked. 
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-3"
                            aria-expanded="false"
                            aria-controls="collapse-1-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-wallet mr-3"></span> How far in advance should I plan my weekend getaway in Utah?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-3"
                            className="collapse"
                            aria-labelledby="heading-1-3"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                We recommend booking your Utah adventure at least a few months in advance. Check for CDC guidelines updates regularly on the websites of the National Parks you plan on visiting. 
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div id="accordion-2" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-1"
                            aria-expanded="false"
                            aria-controls="collapse-2-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span> How do I book everything last minute?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-1"
                            className="collapse"
                            aria-labelledby="heading-2-1"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                                If you decide to visit Utah on short notice, we can plan this adventure for you! Please fill out your <strong>Utah Getaway Inquiry</strong>, and we will get back to you within 24 hrs of your request!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-2"
                            aria-expanded="false"
                            aria-controls="collapse-2-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-lock mr-3"></span> Do you need permits to hike in Utah?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-2"
                            className="collapse"
                            aria-labelledby="heading-2-2"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                            The activities listed above don't require permits. Check for <a target="_blanc" className='intext-link' href="https://www.blm.gov/programs/recreation/passes-and-permits/lotteries-and-permit-systems/utah">exclusive hikes and lottery-based permits</a>.
                              </p>
                            </div>
                          </div>
                      </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-3"
                            aria-expanded="false"
                            aria-controls="collapse-2-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span> How many days do you need to see Utah National Parks?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-3"
                            className="collapse"
                            aria-labelledby="heading-2-3"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                                We highly recommend spending an entire week exploring Utah National Parks. It is however possible to fit your Utah adventure into a long weekend if you pre-plan your trip.
                              </p>
                            </div>
                          </div>
                      </div>
                      <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-4"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-4"
                            aria-expanded="false"
                            aria-controls="collapse-2-4"
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span> What is the most beautiful park in Utah?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-4"
                            className="collapse"
                            aria-labelledby="heading-2-4"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                                Zion is the most beautiful park in Utah. If you have a limited time we recommend you hike Angel's Landing and Narrows trails.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme === 'four' && (
                  <div className="row pt-5">
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>How can I pay for this?</h5>
                        <p>
                          Intrinsicly implement high standards in strategic
                          theme areas via inexpensive solutions. Assertively
                          conceptualize prospective bandwidth whereas
                          client-based imperatives.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is it possible to pay yearly?</h5>
                        <p>
                          Assertively iterate user friendly innovation without
                          open-source markets. Monotonectally extend resource
                          sucking manufactured products without high-payoff
                          paradigms. Objectively customize ubiquitous
                          information before economically sound relationships.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Do you offer discounts on multiple items?</h5>
                        <p>
                          Dramatically target focused testing procedures after
                          holistic ideas. Collaboratively maximize high-payoff
                          ROI and value-added products. Distinctively deliver
                          cooperative collaboration and idea-sharing whereas
                          customized
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is VAT included in plan prices?</h5>
                        <p>
                          Distinctively simplify high-quality initiatives for
                          highly efficient applications. Monotonectally
                          repurpose integrated customer service after magnetic
                          e-services.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Will I pay more for some features?</h5>
                        <p>
                          Enthusiastically pontificate resource-leveling supply
                          chains whereas scalable markets. Authoritatively
                          streamline resource maximizing methods of empowerment
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>
                          Why are there no limits on the number of messages?
                        </h5>
                        <p>
                          Assertively target turnkey ideas for market-driven
                          portals. Appropriately e-enable world-class
                          intellectual capital whereas 2.0 mindshare.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
