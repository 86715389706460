import React from 'react';
import { connect } from 'react-redux';
import { subscribe } from '../../actions/index';
import _data from '../../data';
import Mailchimp from 'react-mailchimp-form'
import { SignalCellularAltSharp } from '@material-ui/icons';

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      email: '',
      disableBtn: false,
      btnText: 'SIGN UP',
      btnClass: 'solid-btn-base',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      hero: _data.hero,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="home"
          className="hero-section pt-100 background-img"
          style={{
            backgroundImage: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between py-5">
              <div className="col-md-7 col-lg-6">
                <div className="hero-content-left text-white">
                  <h1 className="text-white">
                    <span>Get a List of Outdoor Places to Visit near You</span>
                  </h1>
                  <p className="lead">{this.state.hero.description}</p>
                  <form
                    method="post"
                    className="subscribe-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="d-flex align-items-center">
                    <Mailchimp
        action='https://exploroff.us4.list-manage.com/subscribe/post?u=452b54a6f82bcfcbcba1760df&amp;id=01a821aee2'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'youremail@gmail.com',
            type: 'email', 
            required: true
          }
          ]}
          messages = {
    {
      sending: "Sending...",
      success: "Thank you for subscribing!",
      error: "An unexpected internal error has occurred.",
      empty: "You must enter an e-mail.",
      duplicate: "Too many subscribe attempts for this email address",
      button: "Get It"
    }
  }
      className={'button btn ' + this.state.btnClass}                  
        />
                    {/* <input
                      type="submit"
                      className={'button btn ' + this.state.btnClass}
                      id="submit-footer"
                      value={this.state.btnText}
                      disabled={this.state.disableBtn}
                      onClick={() => {
                        this.changeBtnText('Subscribing...')
                      }}
                    /> */}
                    </div>
                  </form>

                  <div className="video-promo-content py-4 d-flex align-items-center">
                    <a
                      href="https://www.youtube.com/watch?v=4UWzLl6Lfjo"
                      className="popup-youtube video-play-icon-without-bip video-play-icon mr-3"
                    >
                      <span className="ti-control-play"></span>
                    </a>{' '}
                    👈🏼 Watch our story
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="hero-animation-img">
                  {/* <img
                    src="https://storage.googleapis.com/exploroff-web/landing-page/app-product.png"
                    alt="app"
                    className="img-fluid"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-img-absolute">
            <img
              src="img/hero-bg-shape-1.svg"
              alt="wave shape"
              className="img-fluid-phone"
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

// function mapStateToProps(state, ownProps) {
//   return {
//     isLoggedIn: state.isLoggedIn,
//   }
// }

export default connect((state) => ({
  state,
}))(HeroSection);
