import React from 'react';
import _data from '../../data';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="utah-pricing"
            className={
              'package-section ptb-55 ' +
              (this.props.bgColor && this.props.bgColor === 'gray'
                ? 'gray-light-bg'
                : 'background-shape-right')
            }
          >
            <div className="container">
              <div className="row justify-content-center">
                {(this.state.price.glacierMemorialPackages || []).map((_package, index) => {
                  return (
                    <div className="col-lg-4 col-md" key={index}>
                      <div
                        className={
                          'card text-center single-pricing-pack ' +
                          (_package.isFeatured ? 'popular-price' : '')
                        }
                      >
                        <div className="pt-4">
                          <h5>{_package.license}</h5>
                        </div>
                        <div className="pricing-img mt-4">
                          <img
                            src={_package.photoImg}
                            alt={_package.photoImg}
                            className="img-fluid"
                          />
                        </div>
                        <div className="card-header py-4 border-0 pricing-header">
                          <div className="h1 text-center mb-0">
                            <span className="price font-weight-bolder">
                              {_package.price}
                            </span>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                            {(_package.features || []).map((feature) => {
                              return <li key={feature}>{feature}</li>;
                            })}
                          </ul>

                          <a
                            href={_package.fareharborURL}
                            className={
                              'btn mb-3 ' +
                              (_package.isFeatured
                                ? 'trip-solid-btn'
                                : 'trip-outline-btn')
                            }
                          >
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme !== 'four' && (
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div id="accordion-1" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-1"
                            aria-expanded="false"
                            aria-controls="collapse-1-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span> How far from Missoula, MT to Glacier National Park?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-1"
                            className="collapse"
                            aria-labelledby="heading-1-1"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                              It's <strong>137.6 miles</strong> from Missoula to Glacier National Park via US-93 N and Mont Hwy 35 W. The average travel time is around 2-3 hrs.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-2"
                            aria-expanded="false"
                            aria-controls="collapse-1-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-gallery mr-3"></span> How far is it from Bozeman to Glacier National Park?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-2"
                            className="collapse"
                            aria-labelledby="heading-1-2"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                              It's <strong>356 miles</strong> from Bozeman to Glacier National Park via US-93 N and Mont Hwy 35 W. The average travel time is around 5-6 hrs.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-3"
                            aria-expanded="false"
                            aria-controls="collapse-1-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-wallet mr-3"></span> Do you need bear spray in Glacier National Park?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-3"
                            className="collapse"
                            aria-labelledby="heading-1-3"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                              Yes. Bear spray is 92% effective in deterring attacks by grizzly, black and polar bears. Please make sure to understand how the bear spray works prior to your trip to Glacier.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div id="accordion-2" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-1"
                            aria-expanded="false"
                            aria-controls="collapse-2-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span> Which is better Yellowstone or Glacier National Park?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-1"
                            className="collapse"
                            aria-labelledby="heading-2-1"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                              According to the National Park ranking, Glacier scores 85/100 while Yellowstone has a 79/100 score. Experts rank parks based on five factors: accessibility, recreation, crowds, amenities, and scenery.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-2"
                            aria-expanded="false"
                            aria-controls="collapse-2-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-lock mr-3"></span> When does Going To The Sun Road open?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-2"
                            className="collapse"
                            aria-labelledby="heading-2-2"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                              There is no set date for the road to open over Logan Pass. Typically, the road has been fully open by early July. Portions of the road may close due to weather. It’s typically fully open until the third Monday of October, but that may change due to weather conditions at any point.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-3"
                            aria-expanded="false"
                            aria-controls="collapse-2-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-gallery mr-3"></span> Do I need a Vehicle Reservation to access Glacier National Park?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-3"
                            className="collapse"
                            aria-labelledby="heading-2-3"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                              A vehicle reservation is required for vehicles entering Going-to-the-Sun Road and the North Fork area between May 27 and September 11, 2022. In addition to a vehicle reservation, you will also need to a park pass.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme === 'four' && (
                  <div className="row pt-5">
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>How can I pay for this?</h5>
                        <p>
                          Intrinsicly implement high standards in strategic
                          theme areas via inexpensive solutions. Assertively
                          conceptualize prospective bandwidth whereas
                          client-based imperatives.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is it possible to pay yearly?</h5>
                        <p>
                          Assertively iterate user friendly innovation without
                          open-source markets. Monotonectally extend resource
                          sucking manufactured products without high-payoff
                          paradigms. Objectively customize ubiquitous
                          information before economically sound relationships.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Do you offer discounts on multiple items?</h5>
                        <p>
                          Dramatically target focused testing procedures after
                          holistic ideas. Collaboratively maximize high-payoff
                          ROI and value-added products. Distinctively deliver
                          cooperative collaboration and idea-sharing whereas
                          customized
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is VAT included in plan prices?</h5>
                        <p>
                          Distinctively simplify high-quality initiatives for
                          highly efficient applications. Monotonectally
                          repurpose integrated customer service after magnetic
                          e-services.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Will I pay more for some features?</h5>
                        <p>
                          Enthusiastically pontificate resource-leveling supply
                          chains whereas scalable markets. Authoritatively
                          streamline resource maximizing methods of empowerment
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>
                          Why are there no limits on the number of messages?
                        </h5>
                        <p>
                          Assertively target turnkey ideas for market-driven
                          portals. Appropriately e-enable world-class
                          intellectual capital whereas 2.0 mindshare.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
