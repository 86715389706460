class Auth {
  authenticated: boolean;
  constructor() {
    this.authenticated = false;
  }

  login(callback: () => void) {
    this.authenticated = true;
    callback();
  }

  logout(callback: () => void) {
    this.authenticated = false;
    callback();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
