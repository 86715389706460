import React from 'react';
import { Link } from 'react-router-dom';

class Story extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="Story"
          className="feature-section ptb-100"
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img align-bottom">
                  <img
                    src="img/glacier-national-park-memorial-day-weekend.png"
                    alt="download"
                    className="img-fluid-mobile circle-img"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>Our experience visiting Glacier</h2>
                  <p>
                  Team Exploroff has visited GNP last year right after memorial 
                  day weekend. The weather was nice and sunny (65° - 75° during the day) 
                  and cooled down at night. We were lucky to stay at <a className='intext-link' target="_blank" href="https://www.glacierparkcollection.com/lodging/west-glacier-village/">West Glacier Village</a>, 
                  bike Going-to-the-Sun Road, eat breakfast at Carolyn's Cafe, and go on a whitewater rafting!
                  </p>
                  <p>
                  We've started our 32-mile ride up the Going-to-the-Sun Road at the Apgar 
                  Visitor Center on the West Side of the park. The road opens for bike 
                  riders early in the season, but it is closed for the vehicles. It was nice to 
                  have the entire road for ourselves, but the ride was very challenging! The views 
                  were spectacular, we've seen a bear! And made a lot of great photos along the way.
                  </p>
                  <p>
                  Although there is a lot to explore you can enjoy the best of Glacier in just a 
                  long weekend! Highly recommend going! Don't forget to check the latest park updates on <a className='intext-link' target="_blank" href="https://www.nps.gov/glac/">NPS.gov</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Story;
