import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import AgencyCoApp from './reducers';
import createSagaMiddleware from 'redux-saga';
import Routes from './routers';
import mySaga from './sagas/subscribe';

const sagaMiddleware = createSagaMiddleware();

// create store
const store = createStore(
  AgencyCoApp,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(mySaga);

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
