import React, { Component } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import PrivacyHeader from '../components/Header/privacyHeader';
import Privacy from '../components/Privacy';
import FooterSection from '../components/Footer';

class PrivacyPage extends Component {
  render() {
    return (
      <>
        <MessengerCustomerChat
          pageId="101590111335192"
          appId="695593052231950" 
        />
        <PrivacyHeader />
        <div className="main">
          <Privacy />
        </div>
        <FooterSection />
      </>
    );
  }
}

export default PrivacyPage;
