import React, { Component } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import PrivacyHeader from '../components/Header/privacyHeader';
import HeroSection from '../components/HeroSection/HeroSection1';
import PromoSection from '../components/PromoSection';
import AboutSection from '../components/AboutUs';
import FeatureSection from '../components/Features';
import Story from '../components/Story';
import DownloadSection from '../components/Download';
import PricingSection from '../components/Pricing/utah';
import TeamMember from '../components/TeamMember';
import SubscribeSection from '../components/Subscribe';
import FooterSection from '../components/Footer';
import MapSection from '../components/Maps/utah';
import InfoSection from '../components/TripInfo/utah';


class UtahNationalParksRoadTrip extends Component {
  render() {
    return (
      <>
        <MessengerCustomerChat
          pageId="101590111335192"
          appId="695593052231950" 
        />
        <PrivacyHeader />
        <div className="main">
          <InfoSection />
          <MapSection />
          <PricingSection hideFaq={false} />
        </div>
        <SubscribeSection />
        <FooterSection />
      </>
    );
  }
}

export default UtahNationalParksRoadTrip;
