import React from 'react'
import { connect } from 'react-redux'
import { subscribe } from '../../actions/index'
import _data from '../../data'
import Mailchimp from 'react-mailchimp-form'



class Subscribe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hero: {},
      email: '',
      disableBtn: false,
      btnText: 'SIGN UP',
      btnClass: 'solid-btn-base',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  changeBtnText = btnText => {
    this.setState({ btnText })
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {}
    stateValue[inputName] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    this.setState(stateValue)
  }

  handleSubmit(event) {
    event.preventDefault()

    // get action
    const subscribeAction = subscribe(this.state)

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction)
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="shape-img subscribe-wrap">
          <img
            src="img/footer-top-shape.png"
            alt="footer shape"
            className="img-fluid"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <form
                  method="post"
                  className="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block"
                  onSubmit={this.handleSubmit}
                >
                  <div className="d-flex align-items-center">
                    <Mailchimp
        action='https://exploroff.us4.list-manage.com/subscribe/post?u=452b54a6f82bcfcbcba1760df&amp;id=01a821aee2'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email to get a list of outdoor places',
            type: 'email',
            required: true
          }
          ]}
          messages = {
    {
      sending: "Sending...",
      success: "Thank you for subscribing!",
      error: "An unexpected internal error has occurred.",
      empty: "You must enter an e-mail.",
      duplicate: "Too many subscribe attempts for this email address",
      button: "Get It"
    }
  }
      className={'signup-button btn ' + this.state.btnClass}  
        />
                    {/* <input
                      type="submit"
                      className={'button btn ' + this.state.btnClass}
                      id="submit-footer"
                      value={this.state.btnText}
                      disabled={this.state.disableBtn}
                      onClick={() => {
                        this.changeBtnText('Subscribing...')
                      }}
                    /> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(state => ({
  state,
}))(Subscribe)
