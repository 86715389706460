import React, { Component } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/HeroSection1';
import PromoSection from '../components/PromoSection';
import AboutSection from '../components/AboutUs';
import VideoLandingPage from '../components/Video/VideoLandingPage';
import FeatureSection from '../components/Features';
import Story from '../components/Story';
import DownloadSection from '../components/Download';
import PricingSection from '../components/Pricing';
import TeamMember from '../components/TeamMember';
import SubscribeSection from '../components/Subscribe';
import FooterSection from '../components/Footer';

class LandingPage extends Component {
  render() {
    return (
      <>
        <MessengerCustomerChat
          pageId="101590111335192"
          appId="695593052231950" 
        />
        <Header />
        <div className="main">
          <HeroSection />
          <PromoSection />
          {/* <DownloadSection /> */}
          {/* <VideoLandingPage showDownloadBtn={true} /> */}
          {/* <Story /> */}
          {/* <PricingSection hideFaq={true} /> */}
          <TeamMember />
          {/* <FeatureSection /> */}
        </div>
        <SubscribeSection />
        <FooterSection />
      </>
    );
  }
}

export default LandingPage;
