import { SUBSCRIBE_SUCCEEDED } from '../constants/types.js'

const initState = [
  {
    email: 'aputra@juniper.net',
  },
]

const getEmail = (state, action) => {
  switch (action.type) {
    case SUBSCRIBE_SUCCEEDED:
      return {
        email: action.email,
      }
    default:
      return state
  }
}

const subscribe = (state = initState, action) => {
  switch (action.type) {
    case SUBSCRIBE_SUCCEEDED:
      return [...state, getEmail(undefined, action)]
    default:
      return state
  }
}

export default subscribe
