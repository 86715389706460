import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="map-utah" className="about-us gray-light-bg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m42!1m12!1m3!1d337259.1316955004!2d-113.98450130651017!3d48.67036063248981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m27!3e0!4m5!1s0x536617b3ee1fbfd7%3A0x27d029065b48b51a!2s12127%20US-2%2C%20West%20Glacier%2C%20MT%2059936!3m2!1d48.484949099999994!2d-114.0013185!4m5!1s0x5366175cc47d2efd%3A0xe5619a3f3692f8db!2s196%20Apgar%20Loop%2C%20West%20Glacier%2C%20MT%2059936!3m2!1d48.526499199999996!2d-113.9940658!4m5!1s0x53661799c1f6dd3d%3A0x590ba33c3f69bfc!2sWest%20Glacier%2C%20MT%2059936!3m2!1d48.6162807!2d-113.8594035!4m3!3m2!1d48.69576!2d-113.717069!4m3!3m2!1d48.79729!2d-113.668719!5e0!3m2!1sen!2sus!4v1652039502255!5m2!1sen!2sus"
            width="100%"
            height="250">
          </iframe>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
