import React from 'react';
import auth from '../auth';

const ManagePage = props => {
  return (
    <>
      <div>ManagePage</div>
      <button
        onClick={() => {
          auth.logout(() => {
            props.history.push('/');
          });
        }}
      >
        Logout
      </button>
    </>
  );
};

export default ManagePage;
