import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="info-nevada" className="about-us pt-trip gray-light-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    {this.props.showTitle && this.props.showTitle === true && (
                      <title className="badge badge-primary badge-pill">
                        Top Outdoor Activities in Las Vegas
                      </title>
                     )}
                    <h1>
                      Top Outdoor Activities in Las Vegas <br />
                    </h1>
                  <p>
                  Outdoor activities in Las Vegas are even more popular than partying at the streep. Take a scenic drive through Valley of Fire State Park, stop at the Hoover Dam, or even take a day trip from Las Vegas to <a href="/utah-national-parks-road-trip">Zion National Park</a>! Spice up your Nevada adventure with hiking, kayaking, desert ATV tours, and exploring the rim of the Grand Canyon West Rim! Click on <strong>"more options"</strong> on google maps below to open up a Full Vegas Outdoor Activities Itinerary.
                  </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
    </React.Fragment>
  );
  }
}
export default About;