import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import OutdoorActivities from '../themes/OutdoorActivities';
import ExampleActivity from '../themes/ExampleActivity';
import LandingPage from '../themes/LandingPage';
import PrivacyPage from '../themes/PrivacyPage';
import ManagePage from '../themes/ManagePage';
import LoginPage from '../themes/LoginPage';
import { ProtectedRoute } from './protected.route';
import UtahNationalParksRoadTrip from '../themes/UtahNationalParksRoadTrip';
import ThingsToDoInVegasBesidesParty from '../themes/ThingsToDoInVegasBesidesParty';
import KayakColoradoRiver from '../themes/KayakColoradoRiver';
import GlacierNationalParkMemorialDay from '../themes/GlacierNationalParkMemorialDay';
export default class Routes extends React.PureComponent {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route exact path="/outdoor-activities" component={OutdoorActivities}
            />{' '}
            */)
            <Route exact path="/utah-national-parks-road-trip" component={UtahNationalParksRoadTrip} />
            <Route exact path="/things-to-do-in-vegas-besides-party" component={ThingsToDoInVegasBesidesParty} />
            <Route exact path="/kayak-colorado-river" component={KayakColoradoRiver} />
            <Route exact path="/glacier-national-park-memorial-day" component={GlacierNationalParkMemorialDay} />
            <Route exact path="/moab-utv-tour" component={ExampleActivity} />
            <ProtectedRoute exact path="/manage" component={ManagePage} />
            <Route path="*" component={() => '404 not found'} />
          </Switch>
        </Router>
      </>
    );
  }
}
