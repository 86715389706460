import React from 'react';
import { connect } from 'react-redux';
import _data from '../../data';

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {},
    };
  }

  componentDidMount() {
    this.setState({
      promo: _data.promo,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-5 yulia-container">
                  <h5>
                    These terms are effective and were last modified on May 2,
                    2020.
                  </h5>
                  <p className="lead text-left">
                    ExplorOFF LLC (“ExplorOFF”, “we”,“our” or “us”) respect your
                    privacy and are committed to protecting it in connection
                    with your use of our products (website, exploroff.com, and
                    mobile app, ExplorOFF, and other products, services and
                    features by ExplorOFF LLC). This privacy policy explains how
                    we collect, process, use, and disclose your personal data
                    when you use our products and services.
                  </p>
                  <h6 className="lead text-uppercase font-weight-bold text-left">
                    Personal information Collection
                  </h6>
                  <p className="lead text-left">
                    When you use any of our products, we may collect the
                    following information from you
                  </p>
                  <ul className="lead yulia-tochki text-left">
                    <li>
                      Personal information such as your identity, contact,
                      profile, financial, or other personal information
                    </li>
                    <li>
                      Apple ID information, if you choose to login to our
                      products with Apple ID
                    </li>
                    <li>
                      Information collected via tracking technologies, including
                      technical, location, and usage data about your device,
                      browsing actions and patterns
                    </li>
                  </ul>
                  <br />
                  <h6 className="lead text-uppercase font-weight-bold text-left">
                    Personal information use
                  </h6>
                  <p className="lead text-left">
                    The security of your personal information is very important
                    to us. We follow generally accepted standards and best
                    practices to protect your personal information. If you have
                    any questions about the security of your personal
                    information, please contact{' '}
                    <a href={'mailto:' + 'yuliavasilyeva1993@gmail.com'}>
                    yuliavasilyeva1993@gmail.com
                    </a>
                    . We do not provide your personal information to any third
                    party without your specific consent. Your personal
                    information may be used for the following reasons:
                  </p>
                  <ul className="lead yulia-tochki text-left">
                    <li>
                      To provide, administer, protect, and enhance our product,
                      to enhance user experiences, and develop new products and
                      services
                    </li>
                    <li>
                      To verify your identity and to protect against
                      unauthorized transactions, fraud, and other unlawful
                      activity
                    </li>
                    <li>
                      To enable certain features connected to our products,
                      ex.registration, badge collection, activity curation, etc.
                    </li>
                    <li>
                      To constantly improve our products, ensure they fit your
                      needs and develop new ideas
                    </li>
                    <li>To provide customer support</li>
                    <li>
                      To manage and develop our relationship and keep you up to
                      date with our latest news
                    </li>
                    <li>
                      To communicate about and administer our products, events,
                      activities, merchants, attractions, locations, special
                      offers (that includes sending marketing and promotional
                      materials). You can update your subscription preferences
                      at any time by following the instructions provided in each
                      channel of communication
                    </li>
                    <li>
                      To administer and protect our business, content, and
                      products including system maintenance, support,
                      troubleshooting, data analysis, testing, reporting and
                      hosting of data and monitoring for unauthorized use
                    </li>
                    <li>
                      To carry out our obligations and enforce our rights
                      arising from any contracts between you and us
                    </li>
                    <li>
                      To fulfill any other purpose for which you provide it or
                      for which you give consent
                    </li>
                    <li>
                      To change of purpose when we reasonably consider we need
                      to use it for another reason compatible with the original
                      purpose. If you wish to get an explanation as to how the
                      processing for the new purpose is compatible with the
                      original purpose, please contact{' '}
                      <a href={'mailto:' + 'yuliavasilyeva1993@gmail.com'}>
                      yuliavasilyeva1993@gmail.com
                      </a>
                    </li>
                    <li>
                      To support our business and who are bound by contractual
                      obligations (ex. affiliates, contractors, service
                      providers, and other third parties)
                    </li>
                    <li>
                      To understand your broad, non-specific geographic location
                      to help us identify groups of users by general geographic
                      market (such as zip code, state or country)
                    </li>
                    <li>
                      To serve our advertisements needs through third party
                      platforms
                    </li>
                    <li>
                      To ask you to complete voluntary surveys that we use for
                      research purposes
                    </li>
                  </ul>
                  <p className="lead text-left">
                    Please note: We do not disclose information about
                    identifiable individuals to companies that host
                    advertisements on our behalf. We may use such aggregate
                    information to help advertisers reach the kind of audience
                    they want to target.
                  </p>
                  <h6 className="lead text-uppercase font-weight-bold text-left">
                    Access to and deletion of personal information
                  </h6>
                  <p className="lead text-left">
                    You may access your personal information at any time via an
                    online account, or by contacting{' '}
                    <a href={'mailto:' + 'yuliavasilyeva1993@gmail.com'}>
                    yuliavasilyeva1993@gmail.com
                    </a>
                    . To request the deletion of your personal data that we have
                    on file please contact{' '}
                    <a href={'mailto:' + 'yuliavasilyeva1993@gmail.com'}>
                    yuliavasilyeva1993@gmail.com
                    </a>
                    .
                  </p>
                  <h6 className="lead text-uppercase font-weight-bold text-left">
                    Links to third party sites
                  </h6>
                  <p className="lead text-left">
                    The activities displayed on our products and some other
                    features contain links to the third party websites. If you
                    follow a link to any of these external websites, please note
                    that these websites have their own privacy policies and that
                    we do not accept any responsibility or liability for these
                    websites or their policies. Please check these policies
                    before you submit any personal information to these external
                    websites.
                  </p>
                  <h6 className="lead text-uppercase font-weight-bold text-left">
                    Use of exploroff by minors
                  </h6>
                  <p className="lead text-left">
                    You must be 18 years of age, to sign up as a registered user
                    of our products. Involvement of adults and consent of a
                    parent or legal guardian is required in any other case.
                  </p>
                  <h6 className="lead text-uppercase font-weight-bold text-left">
                    Changes to our privacy policy
                  </h6>
                  <p className="lead text-left">
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page. We encourage you to periodically review this
                    page for the latest information on our privacy practices.
                  </p>
                  <h6 className="lead text-uppercase font-weight-bold text-left">
                    Contact
                  </h6>
                  <p className="lead text-left">
                    For any questions or concerns regarding this privacy policy
                    please contact{' '}
                    <a href={'mailto:' + 'yuliavasilyeva1993@gmail.com'}>
                    yuliavasilyeva1993@gmail.com
                    </a>
                    .
                  </p>
                  <p className="lead text-left">
                    317 S 6th St
                    <br />
                    Las Vegas
                    <br /> NV 89101
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  state,
}))(Privacy);
